<template>
	<keep-alive>
		<router-view />
	</keep-alive>
</template>

<script>
export default {
	name: 'HomeProductSpecial',
}
</script>

<style scoped lang="scss"></style>
